<div
    class="not-mobile topnav">
    <a matRiplle translate (click)="openWebsiteRoute('home')" id="homeRoute">
        header-menu.home
    </a>
    <a matRiplle translate (click)="openWebsiteRoute('benefits')" id="benefitsRoute">
        header-menu.benefits
    </a>
    <a matRiplle translate (click)="openWebsiteRoute('about-us')" id="aboutUsRoute">
        header-menu.about-us
    </a>
    <div class="separate-line"></div>

    <a class="custom-menu-item" matRiplle translate
        [ngClass]="{
            'active': activeRoute.includes('authentication')
        }"
        #loginPopover="ngbPopover" [ngbPopover]="loginPopup" popoverClass="home-login-popover">
        authentication.login.title
    </a>
</div>

<ng-template #loginPopup>
    <div class="login-options">
        <a matRiplle translate routerLink="/authentication" routerLinkActive="active" id="portalRoute">
            header-menu.portal
        </a>
        <hr>
        <a matRiplle translate (click)="openWebsiteRoute('faq/authentication')" id="faqRoute">
            header-menu.faq
        </a>
        <hr>
        <a matRiplle translate (click)="openWebsiteRoute('consultancy-process/authentication')" id="copRoute">
            header-menu.cop
        </a>
    </div>
</ng-template>
