import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../abstractions/base.component';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent extends BaseComponent {
    constructor() {
        super();
    }

    get activeRoute(): string {
        return this.router.url.split('?')[0];
    }

    openWebsiteRoute(route) {
        window.open(`${environment.websiteBaseURL}/${route}`);
    }
}
