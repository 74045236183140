/* src/app/core/components/header-menu/header-menu.component.scss */
.topnav {
  z-index: 100;
  position: relative;
  display: flex;
  align-items: center;
}
.topnav a {
  font-family: "Montserrat", sans-serif;
  float: right;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  text-shadow: 0px 1px 1px rgba(63, 99, 180, 0.3);
  text-transform: uppercase;
  cursor: pointer;
  color: white;
}
.topnav a:hover {
  transition: 0.4s;
  color: #ffffff;
}
.topnav .active {
  position: relative;
  font-weight: 600;
  color: #6CFFF4;
}
.topnav .active:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #6CFFF4;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
.topnav .active:hover:after {
  transition: 0.4s;
  background: #ffffff;
}
.not-mobile {
  width: 100% !important;
}
.separate-line {
  height: 23px;
  border: 0px solid rgba(255, 255, 255, 0.7);
  width: 1px;
  border-left-width: 1px;
}
.custom-menu-item {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}
::ng-deep .home-login-popover {
  background: rgba(46, 38, 38, 0.6);
  border: none;
  box-shadow: none;
  padding: 0;
  min-width: unset;
  max-width: unset;
  top: 0px !important;
}
::ng-deep .home-login-popover .arrow:before {
  border-bottom: none;
}
::ng-deep .home-login-popover .arrow:after {
  border-bottom-color: rgba(46, 38, 38, 0.6);
  top: 1px;
}
::ng-deep .home-login-popover .active:after {
  display: none;
}
.login-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login-options a {
  font-weight: 600;
  color: white;
  font-size: 13px !important;
  padding: 0.5rem 0 !important;
}
.login-options hr {
  margin: 0;
  border-top: 1px solid rgba(225, 232, 238, 0.5019607843);
  width: 100%;
}
@media only screen and (min-width: 1920px) and (min-height: 1080px) {
  .topnav a {
    font-size: 20px;
  }
}
@media only screen and (min-width: 3440px) and (min-height: 1080px) {
  .topnav a {
    font-size: 20px;
  }
  .in-faq {
    left: unset;
    position: relative;
  }
}
@media only screen and (min-width: 3440px) and (min-height: 1440px) {
  .topnav a {
    font-size: 26px;
  }
}
@media (max-width: 1566px) and (max-height: 768px) {
  .topnav a {
    font-size: 14px;
    padding: 14px 0.5rem;
  }
  .topnav .login-options a {
    font-size: 12px !important;
  }
}
@media (max-width: 1300px) {
  .topnav a {
    font-size: 12px;
  }
}
@media screen and (max-width: 1200px) {
  .topnav a {
    display: none;
    text-shadow: none;
  }
  .topnav .bars {
    float: right;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .topnav .separate-line {
    display: none;
  }
}
/*# sourceMappingURL=header-menu.component-JB42EQIP.css.map */
