/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, OnInit } from '@angular/core';
const version = require('package.json').version;

@Component({
    selector: 'app-version-tag',
    templateUrl: './version-tag.component.html',
    styleUrls: ['./version-tag.component.scss']
})
export class VersionTagComponent implements OnInit {

    version;

    ngOnInit() {
        this.version = `v${version}`;
    }

}
