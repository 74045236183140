import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMenuComponent } from './header-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
    declarations: [HeaderMenuComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatRippleModule,
        NgbPopoverModule
    ],
    exports: [HeaderMenuComponent],
})
export class HeaderMenuModule {}
