import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionTagComponent } from './version-tag.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [VersionTagComponent],
    exports: [VersionTagComponent]
})
export class VersionTagModule { }
